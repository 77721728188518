<div class="back-office">
  <div class="header">
    <img class="mma-logo" src="../../../assets/images/MMA_LOGO_JAUNE_RVB_long.svg" alt="Logo MyMusicAds" /><span class="header-subtitle">&nbsp;Back-Office</span>
    <img *ngIf="currentUser" class="signout" src="../../../assets/icons/logout.svg" alt="Déconnexion" (click)="signOut()" />
  </div>

  <ng-container *ngIf="!currentUser || currentUser?.role === 'none'; else table">
    <div *ngIf="!loading; else loader" class="login">
      <span class="title">Connexion au Back-Office</span>
      <mat-form-field>
        <input type="text" matInput placeholder="Email" [(ngModel)]="email" />
      </mat-form-field>
      <mat-form-field>
        <input [type]="showPassword ? 'text' : 'password'" matInput placeholder="Mot de passe" [(ngModel)]="password" (keyup.enter)="login()" />
        <img [src]="showPassword ? '../../../assets/icons/eyes-barred.svg' : '../../../assets/icons/eyes.svg'" class="toggle-password" (click)="togglePasswordVisibility($event)" />
      </mat-form-field>
      <app-button (click)="login()" size="sm" color="#fff" text="Se connecter"></app-button>
    </div>
  </ng-container>

  <ng-template #table>
    <div class="filters">
      <mat-form-field *ngIf="isCurrentTabCampaigns || isCurrentTabUsers" appearance="standard" class="searchbar">
        <mat-label>Rechercher</mat-label>
        <input *ngIf="isCurrentTabCampaigns; else userSearchBar" matInput [value]="campaignInputFilter" (keyup)="applyCampaignDataFilter($event)" placeholder="Donnée à rechercher" #input />
        <ng-template #userSearchBar>
          <input matInput (keyup)="userInput$.next($event)" placeholder="Donnée à rechercher" #input />
        </ng-template>
      </mat-form-field>
      <mat-checkbox *ngIf="isCurrentTabCampaigns" [checked]="hideFinishedCampaigns" (change)="filterFinishedCampaigns($event.checked)">Cacher les campagnes terminées ?</mat-checkbox>
      <ng-container *ngIf="isCurrentTabUsers">
        <mat-form-field appearance="fill">
          <mat-label>Rôles</mat-label>
          <mat-select [(value)]="selectedRole" (selectionChange)="applyFilter()">
            <mat-option value="">Tous</mat-option>
            <mat-option value="none">Utilisateur</mat-option>
            <mat-option value="subscriber">Abonné</mat-option>
            <ng-container *ngIf="currentUser.role === UserRoleEnum.ADMIN">
              <mat-option value="coach">Coach</mat-option>
              <mat-option value="trainer">Formateur</mat-option>
              <mat-option value="admin">Administrateur</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <app-button text="Ajouter un abonné" (onClick)="openNewSubscriberDialog(currentUser.id)"></app-button>
        <app-button
          *ngIf="currentUser.role === UserRoleEnum.ADMIN || currentUser.role === UserRoleEnum.TRAINER"
          text="Proumouvoir un utilisateur"
          theme="danger"
          (onClick)="openPromoteUserDialog()"
        ></app-button>
        <app-button class="export-button" theme="info" text="Exporter les rapports" (onClick)="exportELearningDataForAllUsers()"></app-button>
        <img *ngIf="currentUser" class="refresh" src="../../../assets/icons/refresh.svg" (click)="refreshUsers()" />
      </ng-container>
      <ng-container *ngIf="isCurrentTabMasterclasses && selectedMasterclassVersion">
        <mat-form-field class="masterclass-version-select-form-field" appearance="fill">
          <mat-label>Version sélectionnée sur le back-office</mat-label>
          <mat-select class="masterclass-version-select" [(value)]="selectedMasterclassVersion" (valueChange)="onVersionChangeClicked($event)">
            <ng-container *ngFor="let masterclassVersion of masterclassVersions">
              <mat-option *ngIf="!masterclassVersion.isTemporary" [ngClass]="{ 'selected-elearning-version': masterclassVersion.id === selectedELearningVersion.id }" [value]="masterclassVersion"
                >{{ masterclassVersion.name }} - {{ masterclassVersion.creationDate | date: 'dd/MM/yyyy HH:mm' }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div class="selected-e-learning-version-wrapper">
          <mat-label class="mini-mat-label">Version sélectionnée sur le e-learning</mat-label>
          <span class="selected-e-learning-version">{{ selectedELearningVersion.name }} - {{ selectedELearningVersion.creationDate | date: 'dd/MM/yyyy HH:mm' }}</span>
        </div>
      </ng-container>
    </div>

    <mat-tab-group (selectedTabChange)="updateTabIndex($event.index)">
      <mat-tab *ngIf="currentUser.role === 'admin'" label="Campagnes">
        <div *ngIf="campaigns.length > 0" class="table-wrapper mat-elevation-z8">
          <table mat-table class="campaign-table" [dataSource]="campaignDataSource" matSort multiTemplateDataRows>
            <ng-container matColumnDef="orderNumber">
              <th class="ordernumber-col" mat-header-cell *matHeaderCellDef mat-sort-header>Num. de commande</th>
              <td mat-cell *matCellDef="let row">#{{ row.orderNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="creationDate">
              <th class="creationdate-col" mat-header-cell *matHeaderCellDef mat-sort-header>Date de création</th>
              <td mat-cell *matCellDef="let row">{{ row.creationDate | date: 'dd/MM/yyyy':'fr-FR' }}</td>
            </ng-container>
            <ng-container matColumnDef="TTCBudget">
              <th class="ttcbudget-col" mat-header-cell *matHeaderCellDef mat-sort-header>Budget TTC</th>
              <td mat-cell *matCellDef="let row">{{ row.TTCBudget }}€</td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
              <td class="undetermined-width-cell" mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>
            <ng-container matColumnDef="links">
              <th class="links-col" mat-header-cell *matHeaderCellDef mat-sort-header>Liens</th>
              <td mat-cell *matCellDef="let row">{{ row.links.length }}</td>
            </ng-container>
            <ng-container matColumnDef="platform">
              <th class="platform-col" mat-header-cell *matHeaderCellDef mat-sort-header>Plateforme</th>
              <td mat-cell *matCellDef="let row">{{ row.platform | titlecase }}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th class="type-col" mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
              <td mat-cell *matCellDef="let row">{{ row.type | titlecase }}</td>
            </ng-container>
            <ng-container matColumnDef="countries">
              <th class="countries-col" mat-header-cell *matHeaderCellDef mat-sort-header>Pays de diffusion</th>
              <td mat-cell *matCellDef="let row">{{ row.countries.length > 1 ? row.countries.length : (row.countries[0] | titlecase) }}</td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th class="name-col" mat-header-cell *matHeaderCellDef mat-sort-header>Nom</th>
              <td mat-cell *matCellDef="let row">{{ row.name | titlecase }}</td>
            </ng-container>
            <ng-container matColumnDef="phoneNumber">
              <th class="phone-col" mat-header-cell *matHeaderCellDef mat-sort-header>Téléphone</th>
              <td mat-cell *matCellDef="let row">{{ row.phoneNumber ?? 'Non renseigné' }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th class="status-col" mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
              <td mat-cell *matCellDef="let row" [ngStyle]="{ color: translateStatus(row.status).color }">{{ translateStatus(row.status).value }}</td>
            </ng-container>
            <ng-container matColumnDef="toSpendBudget">
              <th class="tospendbudget-col" mat-header-cell *matHeaderCellDef mat-sort-header>Budget à dépenser</th>
              <td mat-cell *matCellDef="let row">{{ row.toSpendBudget }}€</td>
            </ng-container>
            <ng-container matColumnDef="commission">
              <th class="commission-col" mat-header-cell *matHeaderCellDef mat-sort-header>Commission</th>
              <td mat-cell *matCellDef="let row">{{ row.commission }}€</td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let row" [attr.colspan]="campaignDataColumns.length">
                <div class="expanded-row secondLine" [@detailExpand]="row == expandedCampaign ? 'expanded' : 'collapsed'">
                  <div class="expanded-column-block links">
                    <span class="expanded-column-title">Liens</span>
                    <div>
                      <div *ngFor="let link of row.links">{{ link }}</div>
                    </div>
                  </div>
                  <div class="expanded-column-block" *ngIf="row.countries.length > 1">
                    <span class="expanded-column-title">Pays de diffusion</span>
                    <div>
                      <div *ngFor="let country of row.countries">{{ country }}</div>
                    </div>
                  </div>
                  <div class="expanded-column-block notes">
                    <mat-form-field appearance="fill" class="note-field">
                      <mat-label>Ajouter une note</mat-label>
                      <textarea matInput [(ngModel)]="row.notes" (ngModelChange)="onNotesChange(row.id, $event)" placeholder="Saisissez vos notes ici"> </textarea>
                    </mat-form-field>
                  </div>
                  <mat-form-field appearance="fill">
                    <mat-label>Statut de la campagne</mat-label>
                    <mat-select [(value)]="row.status" (valueChange)="onStatusChange(row.id, row.status)">
                      <mat-option *ngFor="let status of statusOptions" [value]="status">
                        {{ translateStatus(status).value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="campaignDataColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: campaignDataColumns"
              class="campaign-row"
              [class.campaign-expanded-row]="expandedCampaign === row"
              (click)="expandedCampaign = expandedCampaign === row ? null : row"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="12">Pas de données trouvées {{ campaignInputFilter ? 'pour la recherche ' + campaignInputFilter : '' }}</td>
            </tr>
          </table>
        </div>
        <mat-paginator #campaignPaginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="50" sticky></mat-paginator>
      </mat-tab>

      <mat-tab label="Utilisateurs">
        <ng-container *ngIf="loadingUsers; else usersTable">
          <ng-container *ngTemplateOutlet="loader"></ng-container>
        </ng-container>

        <ng-template #usersTable>
          <div class="table-wrapper mat-elevation-z8">
            <table mat-table class="user-table" [dataSource]="userDataSource" multiTemplateDataRows>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef><span>Email</span></th>
                <td class="undetermined-width-cell" mat-cell *matCellDef="let row">
                  {{ row.email }}
                </td>
              </ng-container>
              <ng-container matColumnDef="surname">
                <th class="user-name-col" mat-header-cell *matHeaderCellDef>Nom</th>
                <td class="flex" mat-cell *matCellDef="let row">
                  <span>{{ row.lastName }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th class="user-name-col" mat-header-cell *matHeaderCellDef>Prénom</th>
                <td class="flex" mat-cell *matCellDef="let row">
                  <span>{{ row.firstName }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="type">
                <th class="type-col" mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let row">{{ determineUserRoleFr(row.role) | titlecase }}</td>
              </ng-container>
              <ng-container matColumnDef="trainer">
                <th class="trainer-col" mat-header-cell *matHeaderCellDef>Formateur</th>
                <td mat-cell *matCellDef="let row">{{ getTrainerInfoFromUsers(userDataSource.data, row.trainerId) | titlecase }}</td>
              </ng-container>
              <ng-container matColumnDef="resetPassword" *ngIf="currentUser.role === 'admin' || currentUser.role === 'trainer'">
                <th class="reset-password-col" mat-header-cell *matHeaderCellDef>Renvoyer le mdp</th>
                <td mat-cell *matCellDef="let row">
                  <img class="reset-password-icon" src="../../../assets/icons/lock-reset.svg" alt="Reset le mot de passe" (click)="resetUserPassword(row); $event.stopPropagation()" />
                </td>
              </ng-container>
              <ng-container *ngIf="currentUser.role === 'admin'" matColumnDef="report">
                <th class="report-col" mat-header-cell *matHeaderCellDef>Rapport</th>
                <td mat-cell *matCellDef="let row">
                  <img class="upload-icon" src="../../../assets/icons/upload.svg" alt="Générer le rapport" (click)="exportELearningDataForUser(row); $event.stopPropagation()" />
                </td>
              </ng-container>
              <ng-container *ngIf="currentUser.role === 'admin' || currentUser.role === 'trainer'" matColumnDef="reportMCQ">
                <th class="report-col" mat-header-cell *matHeaderCellDef>QCM</th>
                <td mat-cell *matCellDef="let row">
                  <img class="upload-icon" src="../../../assets/icons/upload.svg" alt="Générer le rapport des QCM" (click)="exportMCQDataForUser(row); $event.stopPropagation()" />
                </td>
              </ng-container>
              <ng-container matColumnDef="edit">
                <th class="reset-password-col" mat-header-cell *matHeaderCellDef>Editer l'utilisateur</th>
                <td mat-cell *matCellDef="let row">
                  <img class="reset-password-icon" src="../../../assets/icons/edit.svg" alt="Reset le mot de passe" (click)="openEditUserDialog(row); $event.stopPropagation()" />
                </td>
              </ng-container>
              <ng-container *ngIf="currentUser.role === 'admin'" matColumnDef="delete">
                <th class="delete-col" mat-header-cell *matHeaderCellDef>Supprimer</th>
                <td mat-cell *matCellDef="let row">
                  <img class="delete-icon" src="../../../assets/icons/delete.svg" alt="Supprimer l'utilisateur" (click)="deleteUser(row); $event.stopPropagation()" />
                </td>
              </ng-container>
              <ng-container matColumnDef="expandedUserDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="currentUser.role === 'admin' ? userDataColumns.length : trainerUserDataColumns.length">
                  <div class="expanded-row" [@detailExpand]="row == expandedUser ? 'expanded' : 'collapsed'">
                    <div *ngIf="row == expandedUser" class="expanded-column-block flex">
                      <span class="expanded-column-title links">Liens</span>
                      <div>
                        <div class="social-media-link">
                          <span class="borderless-input">Facebook: {{ getFacebookUrl(row) }}</span>
                        </div>
                        <div class="social-media-link">
                          <span class="borderless-input">Instagram: {{ getInstagramUrl(row) }}</span>
                        </div>
                        <div class="social-media-link">
                          <span class="borderless-input">YouTube: {{ getYouTubeEmail(row) }}</span>
                        </div>
                        <div class="social-media-link">
                          <span class="borderless-input">ID Google Ads: {{ getGoogleAdsId(row) }}</span>
                        </div>
                        <div class="social-media-link">
                          <span class="borderless-input">ID Business Manager: {{ getBusinessManagerId(row) }}</span>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="currentUser.role === 'admin'">
                      <app-date-picker
                        class="date-picker"
                        [isCalendarDialog]="true"
                        [initialRange]="row.eLearningData?.learningPeriod"
                        (intervalChange)="onLearningPeriodChange(row, $event)"
                      ></app-date-picker>
                      <mat-form-field *ngIf="currentUser.role === 'admin'" appearance="fill">
                        <mat-label>Type de formation</mat-label>
                        <mat-select [value]="row.eLearningData?.trainingType" (valueChange)="onTrainingTypeChange(row, $event)">
                          <mat-option *ngFor="let trainingType of trainingTypeOptions" [value]="trainingType">
                            {{ trainingType }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </ng-container>
                    <mat-form-field *ngIf="currentUser.role === 'admin'" appearance="fill">
                      <mat-label>Formateur associé</mat-label>
                      <mat-select [(value)]="row.trainerId" (valueChange)="onTrainerChange(row.id, row.trainerId)">
                        <mat-option *ngFor="let trainer of trainerOptions" [value]="trainer.id">
                          {{ trainer.firstName ? (trainer.firstName | titlecase) : trainer.email }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="currentUser.role === 'admin' ? userDataColumns : trainerUserDataColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: currentUser.role === 'admin' ? userDataColumns : trainerUserDataColumns"
                class="campaign-row"
                [class.user-expanded-row]="expandedUser === row"
                (click)="expandedUser = expandedUser === row ? null : row"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedUserDetail']" class="detail-row"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="12">Pas de données trouvées {{ userInputFilter ? 'pour la recherche ' + userInputFilter : '' }}</td>
              </tr>
            </table>
          </div>
        </ng-template>
        <div [hidden]="currentUser?.role === 'none'">
          <mat-paginator #userPaginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="50" sticky></mat-paginator>
        </div>
      </mat-tab>

      <mat-tab class="masterclass-tab" *ngIf="currentUser.role === 'admin'" label="Masterclass">
        <div *ngIf="masterclassLoading" class="masterclass-loading">
          <ng-container *ngTemplateOutlet="loader"></ng-container>
        </div>
        <div class="e-learning-elements-menu">
          <div class="e-learning-elements-header">
            <app-button class="previous-element-button" *ngIf="selectedELearningElement" (click)="goToPreviousELearningElement()" text="<< Revenir à l'élement supérieur"></app-button>
            <span *ngIf="selectedELearningElement">{{ backOfficeELearningService.determineELearningElementLabel(selectedELearningElement) }}</span>
          </div>
          <div class="e-learning-elements" [ngStyle]="{ flexDirection: !selectedELearningElement || selectedELearningElement?.type === 'masterclass' ? 'row' : 'column' }">
            <app-e-learning-element
              *ngFor="let element of backOfficeELearningService.determineElementChildren(selectedELearningElement, selectedMasterclasses); let index = index"
              [elementInfo]="element"
              (elementInfoChange)="editElement($event)"
              [elementType]="backOfficeELearningService.determineELearningElementChildType(selectedELearningElement?.type)"
              (openNewElement)="openNewElement($event)"
              (changeIndex)="changeElementIndex(index, index + $event)"
              (createNewElement)="createNewElement($event.info)"
              (deleteElement)="deleteElement($event)"
            ></app-e-learning-element>
            <ng-container *ngIf="selectedELearningElement?.type !== 'course'">
              <app-e-learning-element
                *ngIf="backOfficeELearningService.determineELearningElementChildType(selectedELearningElement?.type) as type"
                [elementType]="type"
                (openNewElement)="openNewElement($event)"
                (createNewElement)="createNewElement($event.info)"
              ></app-e-learning-element>
            </ng-container>
          </div>
        </div>
        <div class="elearning-buttons">
          <app-button text="Sauvegarder la version" [disabled]="!isMasterclassFormDirty" (onClick)="openNewVersionDialog()"></app-button>
          <app-button text="Visualiser la version" (onClick)="onVisualizeClicked()"></app-button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-template>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
  <iframe id="pdf-iframe" style="display: none"></iframe>
</div>
